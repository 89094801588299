<template>
  <div class="trainVideoList">
    <div class="container">
      <div class="videoBoxTop">
        <div class="videoBoxLabel">{{ typeName }}</div>
      </div>
      <div class="videoList">
        <div class="videoItem" v-for="(item, index) in list" :key="index">
          <div class="videoItemBox">
            <videoCom :path="item.videoUrl"></videoCom>
          </div>
          <div class="videoName hide-one">
            <span class="hide-one">{{ item.name }}</span>
          </div>
        </div>
      </div>
      <!-- 分页 -->
      <div class="paginationBox">
        <a-pagination
          @change="paginationChange"
          :total="total"
          :defaultPageSize="filter.size"
          :show-total="total => `共 ${total} 条`"
        />
      </div>
    </div>
  </div>
</template>
<script setup>
import { computed, onMounted, ref } from "vue";
import videoCom from "@/components/videoCom.vue";
import { useRoute } from "vue-router";
import { videoGetpage } from '@/api/videoApi'
const route = useRoute();
const typeName = computed(() => {
  return route.query.typeName;
});
const pid = computed(() => {
  return route.query.typeId;

})
const list = ref([
  {
    name: "轮椅路肩",
    videoUrl:
      "http://ykl-home-service.oss-cn-beijing.aliyuncs.com/2023-09-07%2011%3A25%3A02/1.mp4"
  }
]);
const filter = ref({
  page: 1,
  size: 16
});
const total = ref(0);
const paginationChange = page => {
  filter.value.page = page;
  getList();
};
const getList = async() => {
  const postData = {
    ...filter.value,
    pid: pid.value,
  }
  const res = await videoGetpage(postData);
  if(res.code == 200) {
    const data = res.data;
    total.value = data.total;
    list.value = data.records;
  }
}
onMounted(() => {
  getList();
})
</script>

<style lang="less" scoped>
.trainVideoList {
  margin-top: 40px;
  .container {
    background-color: #fff;
  }
  .videoBoxTop {
    display: flex;
    justify-content: space-between;
    height: 75px;
    align-items: center;
    padding: 0 25px;
    box-sizing: border-box;
    .videoBoxLabel {
      font-size: 28px;
      font-family: AlibabaPuHuiTiB;
      color: #333333;
      font-weight: bold;
      position: relative;
      &::after {
        position: absolute;
        content: "";
        display: inline-block;
        bottom: -10px;
        left: -25px;
        width: 167px;
        height: 5px;
        background: #d90d0d;
      }
    }
    span {
      font-size: 18px;
      font-family: AlibabaPuHuiTiB;
      color: rgba(51, 51, 51, 0.6);
      cursor: pointer;
      &:hover {
        color: @xtxColor;
      }
    }
  }
  .videoList {
    display: flex;
    padding: 40px;
    box-sizing: border-box;
    flex-wrap: wrap;
    .videoItem {
      margin-right: 30px;
      width: 260px;
      &:hover {
        .videoName {
          color: @xtxColor;
        }
      }
    }
    .videoName {
      height: 60px;
      width: 100%;
      display: flex;
      align-items: center;
      background: #f5f5f5;
      font-size: 18px;
      font-family: PingFangSC-Medium, PingFang SC;
      font-weight: 500;
      color: #333333;
      padding: 0 17px;
      box-sizing: border-box;
      cursor: pointer;
    }
  }
  .videoItemBox {
    width: 260px;
    height: 180px;
  }
  .paginationBox {
    display: flex;
    align-items: center;
    justify-content: center;
    padding-bottom: 30px;
   :deep(.ant-pagination-item-active) {
      border-color: #d90d0d;
      a {
        color: #d90d0d;
      }
    }
  }
}
</style>
